@use '../../../styles/typography.sass' as *

$border-radius: 8px

.FileInput
  position: relative
  display: flex
  align-items: center
  --overlay-bg: var(--color-primary)
  --overlay-border-radius: #{$border-radius}

  &_disabled
    cursor: default
    color: var(--color-disabled-foreground)

  &_disabled
    .FileInput__SelectButton,
    .FileInput__File
      color: var(--color-disabled-foreground)
      border-color: var(--color-disabled-background)

    .FileInput__Preview
      box-shadow: 0 0 2px 0 var(--color-disabled-background)
      opacity: 0.6

    .FileInput__Progress
      opacity: 0.6

  &_empty:not(&_disabled)
    cursor: pointer

    &:hover,
    &:focus
      .FileInput__SelectButton, .FileInput__File
        color: var(--color-primary)
        border-color: var(--color-primary)

      .FileInput__Preview
        box-shadow: 0 0 2px 0 var(--color-primary)

  &_error:not(&_disabled)
    cursor: pointer

    &:hover,
    &:focus
      .FileInput__SelectButton, .FileInput__File
        color: var(--color-negative)
        border-color: var(--color-negative)

      .FileInput__Preview
        box-shadow: 0 0 2px 0 var(--color-negative)

.FileInput__DragNDropCurtain
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
  background: var(--color-surface-container)
  border: 2px dashed var(--color-outline)
  border-radius: $border-radius
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center

  &_highlighted
    border-color: var(--color-primary)

.FileInput__DragNDropIcon
  margin-right: 4px
  display: block

.FileInput__SelectButton
  width: 60px
  height: 60px
  display: inline-flex
  border: 2px dashed var(--color-outline)
  border-radius: $border-radius
  align-items: center
  justify-content: center
  transition: all 0.2s

.FileInput__Preview
  width: 60px
  height: 60px
  object-fit: cover
  border-radius: $border-radius
  box-shadow: 0 0 2px 0 transparent
  transition: all 0.2s

.FileInput__File
  display: flex
  flex-direction: column
  align-items: flex-start
  min-width: 200px
  transition: color 0.2s
  margin-left: 20px
  flex: 1 1 auto

.FileInput__Bar
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center

  .Button
    margin-left: 0.3em

.FileInput__Name
  text-overflow: ellipsis
  white-space: nowrap
  width: calc(100% - 20px - 1em)
  overflow: hidden

.FileInput__Progress
  height: 2px
  width: 100%
  margin-top: 10px
  background-color: var(--color-neutral-container)
  border-radius: 2px
  position: relative
  overflow: hidden

.FileInput__ProgressValue
  position: absolute
  display: block
  width: 100%
  height: 100%
  background-color: transparent
  transform: translateX(-100%)

  .FileInput_error &
    background-color: var(--color-negative)
    transform: translateX(0)

  .FileInput_process &
    background-color: var(--color-primary)

  .FileInput_success &
    background-color: var(--color-positive)
    transform: translateX(0)

.FileInput__Input
  visibility: hidden
  width: 0.1px
  height: 0.1px
