@use '@vlabs/uikit/src/styles/typography.sass' as *

.StatisticsCard
  background: var(--color-surface-container)
  border-radius: 20px
  box-shadow: var( --elevation-shadow-2)
  padding: var(--space-sm)
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%
  .PageHeaderTitle
    @include typ-h5

.Title
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: var(--space-xs)

.Image
  height: 100%
  width: 100%
  object-fit: contain

  // FIXME: нужно пофиксиь копки, чтобы убрать этот стиль
.RoundButton
  gap: 0
