.Select
  &.Select--is-disabled
    .Select__control
      background-color: var(--color-disabled-background)

  &:not(&.Select--is-disabled)
    .Select__control
      cursor: pointer

  .Select__control
    background-color: transparent
    position: relative

    &::before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      background: var(--color-on-surface)
      opacity: 0
      display: block
      transition: opacity 0.1s
      pointer-events: none

    &:hover::before
      opacity: var(--opacity-state-hover)

  .Select__single-value
    color: var(--color-on-surface)

  .Select__placeholder
    color: var(--color-on-surface-variant)

  .Select__input-container
    color: var(--color-on-surface)

  .Select__multi-value
    background: var(--color-disabled-background)

  .Select__multi-value__label
    color: var(--color-on-surface)

  .Select__option
    background: transparent
    position: relative
    color: var(--color-on-surface)

    &::before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      background: var(--color-neutral)
      opacity: 0
      transition: opacity 0.2s ease-in-out

  .Select__option--is-focused
    &::before
      opacity: var(--opacity-state-hover)

  .Select__option--is-selected
    &:hover::before
      opacity: calc(var(--opacity-state-active) + var(--opacity-state-hover))
    &::before
      opacity: var(--opacity-state-active)

  .Select__menu
    background: var(--color-surface-overlay)
    box-shadow: var(--elevation-shadow-2)
    border-radius: 6px
    margin-top: 2px
    overflow: hidden

  .Select__menu-list
    padding: 2px 0

  .Select__placeholder,
  .Select__control,
  .Select__menu
    transition: all 0.3s
    width: auto
    min-width: 100%

  .Select__dropdown-indicators
    padding: 0 4px

  .Select__indicator
    color: var(--color-on-surface-variant2)

  .Select__indicator-separator:not(#fake-id)
    background-color: var(--color-on-surface-variant2)

  .Select__indicator-separator
    background-color: transparent
    transition: all 0.3s

  .Select__indicator,
  .Select__dropdown-indicator
    padding: 0 4px

  .Select__indicator
    cursor: pointer
    transition: all 0.3s

    &:hover
      opacity: 0.7

  &:not(.Select_implicit) .Select__control
    border-radius: 8px
    box-shadow: none
    min-height: 42px
    border-color: var(--color-outline)

  &:not(.Select_implicit) .Select__control--menu-is-open
    min-height: 42px

    .Select__dropdown-indicator
      transform: rotate(180deg)
      transition: all 0.3s

.Select.Select_implicit
  .Select__indicator-separator
    display: none

  .Select__control
    padding: 1px 1px 0 1px
    border: 1px solid transparent
    border-bottom-width: 2px
    border-radius: 0
    box-shadow: none

  &.Select_error .Select__control
    border-bottom-color: var(--color-negative)

.Select_error
  &:not(.Select_implicit) .Select__control
    border-color: var(--color-negative)

  .Select__placeholder
    color: var(--color-negative)
