:root {
  --color-accent-blue: hsla(219, 100%, 68%, 1);
  --color-accent-blue-secondary: hsla(219, 100%, 87%, 1);
  --color-accent-cyan: hsla(197, 100%, 46%, 1);
  --color-accent-cyan-secondary: hsla(197, 84%, 77%, 1);
  --color-accent-turquoise: hsla(184, 60%, 49%, 1);
  --color-accent-turquoise-secondary: hsla(183, 59%, 77%, 1);
  --color-accent-green: hsla(79, 45%, 58%, 1);
  --color-accent-green-secondary: hsla(78, 45%, 83%, 1);
  --color-accent-orange: hsla(27, 100%, 67%, 1);
  --color-accent-orange-secondary: hsla(27, 100%, 86%, 1);
  --color-accent-brown: hsla(20, 45%, 65%, 1);
  --color-accent-brown-secondary: hsla(20, 45%, 86%, 1);
  --color-accent-red: hsla(7, 100%, 71%, 1);
  --color-accent-red-secondary: hsla(7, 100%, 88%, 1);
  --color-accent-pink: hsla(319, 74%, 66%, 1);
  --color-accent-pink-secondary: hsla(319, 73%, 87%, 1);
  --color-accent-violet: hsla(266, 100%, 71%, 1);
  --color-accent-violet-secondary: hsla(266, 94%, 87%, 1);
  --color-on-accent: hsla(0, 0%, 100%, 1);
}
