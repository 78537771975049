@use '@vlabs/uikit/src/index.sass' as *

.SettingsItem
  width: 100%

.Page
  display: flex
  flex-direction: column
  box-sizing: border-box

.ContentWrapper
  display: flex
  flex-direction: row
  flex-grow: 1
  gap: 20px
  height: calc(100vh - 220px)
  box-sizing: border-box
  margin-top: var(--space-xs)

.SettingsPanel
  flex: 1 1 35%
  background-color: var(--color-surface-container)
  overflow-y: auto
  box-sizing: border-box
  display: flex
  flex-direction: column
  justify-content: space-between
  overflow-x: hidden
  box-sizing: border-box
  display: flex
  height: 100%

.TabsWrapper
  padding: var(--space-sm)

.ChartPanel
  flex: 1 1 60%
  padding: var(--space-sm)
  background-color: var(--color-surface-container)
  overflow: hidden
  box-sizing: border-box

.SettingsPanelButtonGroup
  display: flex
  padding: var(--space-sm)
  position: sticky
  bottom: 0
  background-color: var(--color-surface-container)

.ButtonWrapper
  width: 100%

.ChartPanelButtonGroup
  display: flex
  justify-content: flex-end
