@use '../../../../../node_modules/react-checkbox-tree/src/scss/react-checkbox-tree.scss' as *

$control-size: 18px
$default-color: var(--color-on-surface)

.ExporterForm .Tabs__Body
  height: 20vh
  overflow: scroll
  border: 1px solid var(--color-outline-variant)
  border-radius: 8px
  background: var(--color-surface-container)

.react-checkbox-tree
  padding: .5em
  font-size: 14px

.react-checkbox-tree label
  display: flex
  align-items: center
  line-height: 1.5

.rct-collapse
  font-size: 16px

.rct-collapse,
.rct-checkbox,
.rct-node-icon
  vertical-align: middle
  display: flex
  align-items: center

  *
    display: inline
    margin: 0
    width: $control-size
    height: $control-size
    color: $default-color

.rct-option-collapse-all,
.rct-option-expand-all
  svg
    width: $control-size
    height: $control-size

.rct-options
  color: $default-color
  position: fixed
