@use '@vlabs/uikit/src/styles/typography.sass' as *

.FormRow
  display: flex
  padding-top: 12px

.InputContainer
  width: 100%

.CoordinateRoot
  display: flex
  justify-content: space-between
  align-items: center

.CoordinateDisplay
  display: flex
  align-items: center
  gap: 8px

.CoordinateDisplayLabel
  @include typ-body-1
  color: var(--color-on-surface-variant2)

.CoordinateDisplayValue
  @include typ-subtitle-1
