.Root
  grid-area: filters
  overflow-x: hidden
  box-sizing: border-box
  display: flex
  flex-direction: column
  justify-content: space-between
  height: calc(100vh - 80px)
  background-color: var(--color-surface-container)

.Content
  padding: 10px

.ButtonPanel
  display: flex
  justify-content: space-between
