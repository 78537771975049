.ButtonToggleGroupWithPermissions
  display: inline-flex

.ButtonToggle__Tooltip
  display: inline-flex
  width: 100%
  height: 100%

  .Button.ButtonToggleGroupWithPermissions__Item
    box-sizing: border-box
    border-radius: 0
    width: inherit
    
    &:not(&[disabled]) + &
      border-left-width: 0px

    &[disabled]:not(:last-child)
      border-right-width: 0px

  &:first-of-type
    .Button.ButtonToggleGroupWithPermissions__Item
      border-radius: 4px 0 0 4px

  &:last-of-type
    .Button.ButtonToggleGroupWithPermissions__Item
      border-radius: 0 4px 4px 0

  .Button__Content
    overflow: visible
    height: 36px
