.PageHeaderWrapper
  display: flex
  justify-content: space-between
  align-items: center

.PageHeader
  display: flex

.PageHeaderTitle
  font-style: normal
  font-weight: 600
  font-size: 26px
  line-height: var(--space)
  letter-spacing: 0.2px
  margin: 0

.PageHeaderStatus
  display: flex
  align-items: center

.PageHeaderActions
  display: flex
