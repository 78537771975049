$gutter-meta: 5px

.Root
  background: var(--color-surface-container)
  border-radius: 8px
  min-height: 360px
  position: relative
  width: 200px
  border: 1px solid var(--color-outline)
  height: max-content
  margin-right: 15px
  margin-bottom: 15px

.PhotoContainer
  position: relative
  height: 210px
  max-width: 297px
  margin: auto
  overflow: hidden
  border-bottom: 1px solid var(--color-outline)
  border-top-left-radius: 8px
  border-top-right-radius: 8px

.Photo
  height: 210px
  width: 100%
  border: none
  border-radius: 0

.MetaContainer
  padding: 10px
  margin-top: -$gutter-meta

  & > *
    margin-top: $gutter-meta

.EmptyImage
  border: none
  width: 100%
  height: 100%

.Row
  display: flex
  align-items: center

.Row__Value
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.Icon
  display: flex
  align-items: center
