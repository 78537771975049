:root
  --toastify-color-light: var(--color-surface-overlay)

  --toastify-color-progress-light: var(--color-primary)
  --toastify-color-progress-info: var(--color-neutral)
  --toastify-icon-color-info: var(--color-neutral)

  --toastify-color-success: var(--color-positive-variant)
  --toastify-color-warning: var(--color-attention-variant)
  --toastify-color-error: var(--color-negative-variant)

.Toastify__toast
  border-radius: 12px
  box-shadow: var(--elevation-shadow-2)
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.1px
  color: var(--color-on-surface)
  min-width: 200px
  max-width: 400px
  min-height: 54px

  &:hover .Toastify__close-button
    opacity: 0.3

.Toastify__toast-body
  max-width: 100%
  align-items: flex-start
  padding: 8px
  margin: 0

  div
    min-width: 0

.Toastify__close-button
  color: var(--color-on-surface)
  position: absolute
  width: 16px
  height: 16px
  right: 8px
  top: 8px
  opacity: 0
  svg
    width: 100%
    height: 100%
  &:hover
    opacity: 1 !important

.Toastify__toast-accent
  &.Toastify__toast
    box-shadow: none
    background: var(--color-inverse-surface)
    color: var(--color-inverse-on-surface-primary)
  & .Toastify__close-button
    color: var(--color-inverse-on-surface-secondary)
    &:hover
      color: var(--color-inverse-on-surface)

.Toastify__close-button
  opacity: 0
  color: var(--color-on-surface-variant)
  &:hover
    color: var(--color-on-surface)
  .Toastify__toast:hover &
    opacity: 1

.Toastify__toast-icon
  width: 21px
  height: 21px
  margin: 1.5px 13.5px 0 1.5px
