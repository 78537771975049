.Panel
  border-radius: 8px
  height: 100%
  background: var(--color-surface-container)
  box-shadow: var(--elevation-shadow-1)

  &_noBorder
    border: 0

  &_withPadding
    padding: var(--space-sm)
