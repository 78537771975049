@use '@vlabs/uikit/src/styles/typography.sass' as *

.Page
  padding: 10px
  max-height: 80vh
  overflow: auto
  width: 1100px

.BlocksWrapper
  padding: 16px 10px

  & + &
    border-top: 1px solid var(--color-outline-variant)

  &_flex
    display: flex
    justify-content: space-between
    align-items: center

.InputLabel
  padding-bottom: 5px

.BasicAttributesFilter
  display: flex
  justify-content: space-between

.Subtitle
  @include typ-subtitle-1

.Width_1
  width: 322px

.Width_2
  width: 255px

.MatchPolicy__Title
  @include typ-small-1
