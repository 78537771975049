.Card
  width: 145px
  height: 145px
  position: relative
  perspective: 1000px

  &:hover
    .Front
      transform: rotateY(180deg)

    .Back
      transform: rotateY(360deg)

.Front,
.Back
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
  transition: all .4s
  backface-visibility: hidden
  border: 1px solid var(--color-outline)

.Back
  background-color: var(--color-surface-container)
  transform: rotateY(180deg)

.Root
  box-sizing: border-box
  display: flex
  flex-direction: column
  height: 100%

.ImageBlock
  text-align: center
  display: flex
  align-items: center
  flex-direction: column
  justify-content: space-between
  height: 100%
  width: 100%
  box-sizing: border-box

.ColorBlock
  border-top: 1px solid var(--color-outline)
  display: flex
  align-items: center

.ColorLabel
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  height: 15px
  margin-left: -15px

.Color
  width: 15px
  height: 15px
  border-right: 1px solid var(--color-outline)

  &_other
    border-right: none

  &_undefined
    border-right: none

  &_black
    background: rgb(0,0,0)

  &_blue
    background: rgb(0,0,255)

  &_green
    background: rgb(0,128,0)

  &_gray
    background: rgb(128,128,128)

  &_orange
    background: rgb(255,165,0)

  &_purple
    background: rgb(128,0,128)

  &_red
    background: rgb(255,0,0)

  &_white
    background: rgb(255, 255, 255)

  &_yellow
    background: rgb(255,255,0)

  &_pink
    background: rgb(255,192,203)

  &_brown
    background: rgb(165,42,42)

  &_beige
    background: rgb(245,245,220)

  &_khaki
    background: rgb(189,183,107)

  &_multicolored
    background: linear-gradient(168.52deg, #FFDF30 5.41%, #FFB82E 15.1%, #FF8D00 24.95%, #FF373F 35.62%, #FC0052 42.09%, #C40069 50.3%, #6C1687 58.33%, #732B86 63.96%, #0076CC 72.95%, #00B0C5 82.3%, #73D4EB 91.37%)

.Image
  height: 65px
  width: 65px

.Temperature
  height: 145px
  width: 145px
  box-sizing: border-box
  border: 1px solid var(--color-outline)
  display: flex
  flex-direction: column
  display: flex
  justify-content: center
  align-items: center
  font-size: 26px

  &_normal
    color: var(--color-positive)
    border-color: var(--color-positive)

  &_high
    color: var(--color-negative)
    border-color: var(--color-negative)

  &_abnormal
    color: var(--color-attention)
    border-color: var(--color-attention)

.Tooltip
  margin: 5px

.TooltipValue
  font-weight: bold
