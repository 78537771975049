.VerifyForm
  padding: var(--space-sm)

.VerifyFormPhotoContainer
  height: 100%
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center

.EmptyFaceMessage
  min-height: 256px
  min-width: 250px
  border-radius: 8px
  border: 1px dashed
  padding: 1em
  box-sizing: border-box
  display: flex
  flex-direction: column
  justify-content: center
  text-align: center
  align-items: center
  color: var(--color-on-surface-variant)

.FacePhotoContainer
  position: relative
  // FIXME это обрезает фотки - пока лучше пусть скачет высота
  // min-height: 256px
  max-width: 300px
  margin: auto
  overflow: hidden
  border-radius: 8px
  display: flex
  align-items: center

.FaceCardTag
  position: absolute
  top: 10px
  right: 10px

.VerifyFormFaceHolder
  min-height: 256px
  min-width: 250px
  width: 100%
  height: 100%
  object-fit: cover

.VerifyFormBottom
  display: flex
  justify-content: flex-end
  align-items: center

.VerifyFormCloseIcon
  transition: color 0.3s
  color: var(--color-neutral)
  padding: 10px
  cursor: pointer

  &:hover
    color: var(--color-primary)
