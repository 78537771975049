@use '../../../styles/typography.sass' as *

.Footer
  background: var(--color-surface-container)

.Footer__Text
  display: flex
  justify-content: center
  align-items: center
  padding: 1em
  color: var(--color-on-surface-variant)
