@use '../../../styles/typography.sass' as *

$iconSize: 18px

.AccountMenuItem
  transition: all 0.1s
  display: flex
  align-items: center
  padding: 8px 10px
  width: 100%
  color: var(--color-on-surface)
  text-decoration: none
  position: relative

  &_noIcon
    padding-left: 12px

  &:first-child
    padding-top: 12px

  &:last-child
    padding-bottom: 12px

.AccountMenuItem_button
  background-color: transparent
  border: 0
  cursor: pointer

.AccountMenuItem__Icon
  height: $iconSize
  width: $iconSize
  margin-right: 8px

.AccountMenuItem__Icon svg
  height: $iconSize
  width: $iconSize

.App__Sticky
  position: sticky
  top: 0
  z-index: 2

.SelectedItemOverlay
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  opacity: var(--opacity-state-active)
  background: var(--overlay-bg, var(--color-neutral))
