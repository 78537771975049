@use '@vlabs/uikit/src/styles/typography.sass' as *

.Root
  display: flex
  flex-direction: column

.BodyContainer
  display: flex
  flex-wrap: wrap
  padding-bottom: 5px

  & > *
    padding-right: 10px
    padding-top: 5px

.Attributes
  display: flex
  flex-wrap: wrap
  padding-bottom: 5px

  & > *
    padding-right: 10px
    padding-top: 5px
