$sampleSize: 150px

.FaceInfoCell
  display: flex

.FaceTopMatchCell
  padding: 5px

.FaceInfoRow
  min-width: 200px

  &:not(:last-child)
    margin-right: 15px

.Sample__face
  height: $sampleSize
  width: $sampleSize

.RowInfoWidth
  display: flex
  flex-direction: column
  margin-left: 10px

.SimilarityTag
  font-size: 14px !important
  padding: 5px 5px !important
  text-align: center

.InfoRow_wrapper
  width: 100%
  padding-top: 5px
  margin-right: 10px
