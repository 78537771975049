@use '../../../styles/typography.sass' as *

@font-face
  font-family: pass
  src: url('data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATsAA8AAAAAB2QAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAcg9+z70dERUYAAAF0AAAAHAAAAB4AJwANT1MvMgAAAZAAAAA/AAAAYH7AkBhjbWFwAAAB0AAAAFkAAAFqZowMx2N2dCAAAAIsAAAABAAAAAQAIgKIZ2FzcAAAAjAAAAAIAAAACAAAABBnbHlmAAACOAAAALkAAAE0MwNYJ2hlYWQAAAL0AAAAMAAAADYPA2KgaGhlYQAAAyQAAAAeAAAAJAU+ATJobXR4AAADRAAAABwAAAAcCPoA6mxvY2EAAANgAAAAEAAAABAA5gFMbWF4cAAAA3AAAAAaAAAAIAAKAE9uYW1lAAADjAAAARYAAAIgB4hZ03Bvc3QAAASkAAAAPgAAAE5Ojr8ld2ViZgAABOQAAAAGAAAABuK7WtIAAAABAAAAANXulPUAAAAA1viLwQAAAADW+JM4eNpjYGRgYOABYjEgZmJgBEI2IGYB8xgAA+AANXjaY2BifMg4gYGVgYVBAwOeYEAFjMgcp8yiFAYHBl7VP8wx/94wpDDHMIoo2DP8B8kx2TLHACkFBkYA8/IL3QB42mNgYGBmgGAZBkYGEEgB8hjBfBYGDyDNx8DBwMTABmTxMigoKKmeV/3z/z9YJTKf8f/X/4/vP7pldosLag4SYATqhgkyMgEJJnQFECcMOGChndEAfOwRuAAAAAAiAogAAQAB//8AD3jaY2BiUGJgYDRiWsXAzMDOoLeRkUHfZhM7C8Nbo41srHdsNjEzAZkMG5lBwqwg4U3sbIx/bDYxgsSNBRUF1Y0FlZUYBd6dOcO06m+YElMa0DiGJIZUxjuM9xjkGRhU2djZlJXU1UDQ1MTcDASNjcTFQFBUBGjYEkkVMJCU4gcCKRTeHCk+fn4+KSllsJiUJEhMUgrMUQbZk8bgz/iA8SRR9qzAY087FjEYD2QPDDAzMFgyAwC39TCRAAAAeNpjYGRgYADid/fqneL5bb4yyLMwgMC1H90HIfRkCxDN+IBpFZDiYGAC8QBbSwuceNpjYGRgYI7594aBgcmOAQgYHzAwMqACdgBbWQN0AAABdgAiAAAAAAAAAAABFAAAAj4AYgI+AGYB9AAAAAAAKgAqACoAKgBeAJIAmnjaY2BkYGBgZ1BgYGIAAUYGBNADEQAFQQBaAAB42o2PwUrDQBCGvzVV9GAQDx485exBY1CU3PQgVgIFI9prlVqDwcZNC/oSPoKP4HNUfQLfxYN/NytCe5GwO9/88+/MBAh5I8C0VoAtnYYNa8oaXpAn9RxIP/XcIqLreZENnjwvyfPieVVdXj2H7DHxPJH/2/M7sVn3/MGyOfb8SWjOGv4K2DRdctpkmtqhos+D6ISh4kiUUXDj1Fr3Bc/Oc0vPqec6A8aUyu1cdTaPZvyXyqz6Fm5axC7bxHOv/r/dnbSRXCk7+mpVrOqVtFqdp3NKxaHUgeod9cm40rtrzfrt2OyQa8fppCO9tk7d1x0rpiQcuDuRkjjtkHt16ctbuf/radZY52/PnEcphXpZOcofiEZNcQAAeNpjYGIAg///GBgZsAF2BgZGJkZmBmaGdkYWRla29JzKggxD9tK8TAMDAxc2D0MLU2NjENfI1M0ZACUXCrsAAAABWtLiugAA') format('woff')

// TODO: сделать стили disabled и readonly инпутов более контрастными.
//  Плюс явно обозначить стили для плейсхолдера и текста внутри.
//  Сейчас это всё нечитаемо

$border-radius: 8px

.InputField
  display: flex
  flex-direction: column
  align-items: flex-start
  position: relative
  background: var(--color-surface-container)
  border-radius: $border-radius
  --overlay-border-radius: #{$border-radius}

  &_disabled
    background: var(--color-disabled-background)

.InputField__Overlay
  background: var(--color-neutral)
  border-radius: $border-radius

  .InputField:not(.InputField_disabled):hover &
    opacity: var(--opacity-state-hover)

.InputField__Input
  @include typ-body-1
  width: 100%
  padding: 7px 11px 7px 11px
  transition: color 0.3s
  caret-color: var(--color-primary)
  color: var(--color-on-surface)
  background: transparent

  &[disabled]
    cursor: default
    color: var(--color-disabled-foreground)
  &[type=password]:not(:placeholder-shown)
    font-family: pass
    font-size: 12px
    letter-spacing: 1.3px

.InputField_style-default
  .InputField__Input
    border: 1px solid var(--color-outline)
    border-radius: $border-radius

    &[disabled]
      border-color: var(--color-disabled-background)

    &:focus
      border-color: var(--color-primary)
      outline: none

.InputField_style-implicit
  &.InputField,
  .InputField__Overlay
    border-radius: 0

  .InputField__Input
    border: 0
    border-bottom: 1px solid var(--color-neutral)

    &:focus
      border-color: var(--color-primary)
      outline: none

  .Button.InputField__Clear
    right: 0

.InputField_error
  .InputField__Input
    border-color: var(--color-negative)

    &:focus
      border-color: var(--color-negative)

  &.InputField_style-implicit
    border-bottom-color: var(--color-negative)

.InputField_withIcon .InputField__Input
  padding-left: calc(1.5em + var(--space-sm))

.InputField__Input_withButton
  padding-right: calc(1.5em + var(--space-sm))

.InputField__Icon
  width: 20px
  height: 20px
  position: absolute
  left: 12px
  top: 50%
  transform: translateY(-50%)
  color: var(--color-outline)
  display: flex
  align-items: center
  justify-content: space-between

  .InputField_disabled &
    color: var(--color-disabled-foreground)

.Button.InputField__Clear
  height: 20px
  width: 20px
  min-width: 20px
  padding: 4px
  color: var(--color-outline)
  position: absolute
  right: 12px
  top: 50%
  transform: translateY(-50%)

  & .Button__Icon
    height: 12px

.Button.InputField__Hide.InputField__Hide
  height: 20px
  width: 20px
  min-width: 20px
  padding: 0
  color: var(--color-on-surface-variant)
  position: absolute
  right: 12px
  top: 50%
  transform: translateY(-50%)
  transition: all 0.1s ease-in-out

  &:hover
    color: var(--color-on-surface-variant2)
