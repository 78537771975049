*[data-theme="light"] {
  color-scheme: light;

  --color-primary:  hsla(219, 100%, 50%, 1);
  --color-on-primary: hsla(0, 0%, 100%, 1);
  --color-primary-container: hsla(219, 100%, 92%, 1);
  --color-on-primary-container: hsla(219, 30%, 16%, 1);
  
  --color-neutral: hsla(219, 12%, 47%, 1);
  --color-on-neutral: hsla(219, 100%, 99%, 1);
  --color-neutral-container: hsla(219, 27%, 94%, 1);
  --color-on-neutral-container: hsla(219, 100%, 7%, 1);
  
  --color-positive: hsla(135, 100%, 30%, 1);
  --color-positive-variant: hsla(135, 70%, 45%, 1);
  --color-positive-container: hsla(135, 61%, 80%, 1);
  --color-positive-container-dim: hsla(135, 61%, 90%, 1);
  --color-on-positive: hsla(0, 0%, 100%, 1);
  --color-on-positive-container: hsla(135, 100%, 20%, 1);

  --color-attention: hsla(35, 100%, 45%, 1);
  --color-attention-variant: hsla(35, 100%, 50%, 1);
  --color-attention-container: hsla(35, 100%, 80%, 1);
  --color-attention-container-dim: hsla(35, 100%, 90%, 1);
  --color-on-attention: hsla(0, 0%, 100%, 1);
  --color-on-attention-container: hsla(35, 100%, 20%, 1);

  --color-negative: hsla(3, 100%, 40%, 1);
  --color-negative-variant: hsla(3, 100%, 60%, 1);
  --color-negative-container: hsla(3, 100%, 90%, 1);
  --color-negative-container-dim: hsla(3, 100%, 95%, 1);
  --color-on-negative: hsla(0, 0%, 100%, 1);
  --color-on-negative-container: hsla(3, 100%, 20%, 1);

  --color-disabled-background: hsla(219, 27%, 94%, 1);
  --color-disabled-foreground: hsla(219, 18%, 71%, 1);

  --color-surface: hsla(219, 100%, 99%, 1);
  --color-surface-container: hsla(0, 0%, 100%, 1);
  --color-surface-container-high: hsla(219, 27%, 95%, 1);
  --color-surface-variant: hsla(219, 25%, 97%, 1);
  --color-on-surface: hsla(219, 100%, 7%, 1);
  --color-on-surface-variant: hsla(219, 12%, 47%, 1);
  --color-on-surface-variant2: hsla(219, 12%, 60%, 1);
  --color-surface-overlay: hsla(0, 0%, 100%, 1);
  
  --color-inverse-surface: hsla(219, 27%, 95%, 1);
  --color-inverse-on-surface-primary: hsla(0, 0%, 100%, 1);
  --color-inverse-on-surface-secondary: hsla(219, 24%, 70%, 1);

  --color-outline: hsla(219, 17%, 76%, 1);
  --color-outline-variant: hsla(219, 20%, 88%, 1);
  --color-outline-variant2: hsla(219, 27%, 94%, 1);

  --color-primary-hover: hsla(219, 100%, 50%, 0.08);
  --color-primary-active: hsla(219, 100%, 50%, 0.12);
  --color-on-primary-hover: hsla(0, 0%, 0%, 0.08);
  --color-on-primary-active: hsla(0, 0%, 0%, 0.12);

  --color-neutral-hover: hsla(219, 12%, 47%, 0.08);
  --color-neutral-active: hsla(219, 12%, 47%, 0.12);

  --color-negative-hover: hsla(3, 100%, 40%, 0.08);
  --color-negative-active: hsla(3, 100%, 40%, 0.12);

  --color-attention-hover: hsla(35, 100%, 45%, 0.08);
  --color-attention-active: hsla(35, 100%, 45%, 0.12);

  --color-positive-hover: hsla(135, 100%, 30%, 0.08);
  --color-positive-active: hsla(135, 100%, 30%, 0.12);

  --color-elevation1-color1: hsla(219, 68%, 22%, 0.16);
  --color-elevation1-color2: hsla(219, 68%, 22%, 0.02);

  --color-elevation2-color1: hsla(219, 68%, 22%, 0.16);
  --color-elevation2-color2: hsla(219, 68%, 22%, 0.03);

  --color-elevation3-color1: hsla(219, 68%, 22%, 0.16);
  --color-elevation3-color2: hsla(219, 68%, 22%, 0.04);
}
