@use '../../styles/typography.sass' as *

.Tag
  border-radius: 4px
  width: fit-content
  display: flex
  justify-content: center
  align-items: center
  white-space: nowrap
  position: relative

  &__size
    &_medium
      @include typ-small-1
      min-width: 24px
      max-width: 200px
      height: 24px
      padding: 4px 8px
      &.Tag__withIcon
        padding-left: 22px

    &_large
      @include typ-subtitle-2
      min-width: 28px
      max-width: 240px
      height: 28px
      padding: 4px 10px
      &.Tag__withIcon
        padding-left: 28px

  &__kind
    &_primary
      background: var(--color-primary)
      color: var(--color-on-primary)

    &_positive
      background: var(--color-positive-variant)
      color: var(--color-on-positive)

    &_attention
      background: var(--color-attention-variant)
      color: var(--color-on-attention)

    &_negative
      background: var(--color-negative-variant)
      color: var(--color-on-negative)

    &_secondary
      background: var(--color-primary-container)
      color: var(--color-on-primary-container)

    &_neutral
      background: var(--color-neutral-container)
      color: var(--color-on-primary-container)

  &__shape
    &_round
      border-radius: 20px

    &_square
      border-radius: 4px

  .Tooltip__Wrapper
    width: 100%
    height: 100%

.Tag_content
  display: inline-block
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.Tag_icon
  position: absolute
  top: 50%
  left: 6px
  transform: translateY(-50%)
  & > svg
    width: 100%
    height: 100%
    object-fit: cover
  .Tag__size_large &
    width: 16px
    height: 16px
  .Tag__size_medium &
    width: 12px
    height: 12px
