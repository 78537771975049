@use '@vlabs/uikit/src/styles/typography.sass' as *
@use 'sass:math'

$sampleSize: 150px
$sampleBodyHeight: 300px

.BaseSample
  width: $sampleSize
  height: $sampleSize
  position: relative
  border-radius: 8px
  & .BaseSample__Image
    border-radius: inherit

.BaseSample_body
  width: math.div($sampleBodyHeight, 2)
  height: $sampleBodyHeight

.BaseSample__Image
  height: inherit
  width: inherit
  object-fit: cover

.BaseSample__Image_body
  object-fit: contain

.BaseSample__EmptyImage
  font-size: 9px
  height: inherit
  width: inherit

.BaseSample__Loader
  z-index: var(--z-index-sticky)
  width: 150px
  height: 150px
  position: absolute
  border-radius: 8px
  border: 1px solid var(--color-outline-variant)
  color: var(--color-on-surface)
  background: var(--color-surface-container-high)
  display: flex
  justify-content: center
  top: 0
  align-items: center

.BaseSample__Loader_body
  width: math.div($sampleBodyHeight, 2)
  height: $sampleBodyHeight
