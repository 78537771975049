@use '../../styles/typography.sass' as *

.Input__Label
  @include typ-body-2

.Input__Label + .InputField,
.Input__Label + .Select,
.Input__Label + .Textarea
  margin-top: 4px

.Input__Label:has(+ .InputField > input:disabled)
  color: var(--color-disabled-foreground)
