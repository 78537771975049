@use '../../styles/typography.sass' as *
@use '../controls/button/base-button/BaseButton.sass'

$margin: 4px

.ButtonScrimGroup
  background: var(--color-surface-container)
  display: inline-flex
  justify-content: space-between
  align-items: center
  height: 36px
  border-radius: 18px

  &_oneButton
    padding: 0

  &_positioned
    position: absolute

  &_position-top-left,
  &_position-top-center,
  &_position-top-right
    top: $margin

  &_position-bottom-left,
  &_position-bottom-center,
  &_position-bottom-right
    bottom: $margin

  &_position-top-left,
  &_position-bottom-left
    left: $margin

  &_position-top-right,
  &_position-bottom-right
    right: $margin

  &_position-top-center,
  &_position-bottom-center
    right: 50%
    transform: translateX(50%)

.ButtonScrimGroup__Item
  color: var(--color-neutral)

  &:not(:first-child)
    padding-left: 0

  &:not(:last-child)
    padding-right: 0

  &:not(#fakeid)
    &.Button_negative
      --color: var(--color-negative)

    &.Button_positive
      --color: var(--color-positive)

    &.Button_attention
      --color: var(--color-attention)

    &.Button_primary
      --color: var(--color-primary)

    &.Button_neutral
      --color: var(--color-neutral)
