@use './typography.sass' as *

:root
  --space: 32px
  --space-sm: 16px
  --space-xs: 8px

body
  margin: 0
  font-size: 14px
  font-family: 'Open Sans', sans-serif
  --toastify-font-family: 'Open Sans', sans-serif
  color: var(--color-on-surface)

h1,
.h1
  @include typ-h1

h2,
.h2
  @include typ-h2

h3,
.h3
  @include typ-h3

h4,
.h4
  @include typ-h4

h5,
.h5
  @include typ-h5

h6,
.h6
  @include typ-h6

.Subtitle-1
  @include typ-subtitle-1

.Subtitle-2
  @include typ-subtitle-2

.Body-1
  @include typ-body-1

.Body-2
  @include typ-body-2

.Small-1
  @include typ-small-1

.Small-2
  @include typ-small-2

.Small-3
  @include typ-small-3

.Initials
  @include typ-initials

.flex
  display: flex

.flex-col
  flex-direction: column

.grow
  flex-grow: 1

.grow-0
  flex-grow: 0

.shrink
  flex-shrink: 1

.shrink-0
  flex-shrink: 0

.basis-auto
  flex-basis: auto

.w-full
  width: 100%

.h-full
  height: 100%

.overflow-auto
  overflow: auto

.overflow-hidden
  overflow: hidden

.hidden:not(#fake-id)
  display: none

.notransition:not(#fake-id)
  transition: none

.Scrim
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  pointer-events: none
  background: var(--color-inverse-surface)
  opacity: 0.4

.InteractionOverlay
  content: ''
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  opacity: 0
  transition: opacity 0.1s, border-radius 0.1s
  pointer-events: none
  background: var(--overlay-bg, var(--color-neutral))
  border-radius: var(--overlay-border-radius, 0)

:where(.InteractionWrapper)
  position: relative

.InteractionWrapper
  &:hover > .InteractionOverlay,
  &:focus-visible > .InteractionOverlay
    opacity: var(--opacity-state-hover)

  &:active > .InteractionOverlay
    opacity: var(--opacity-state-active)

.InteractionOverlay_dense
  --opacity-state-hover: var(--opacity-state-hover-dense)
  --opacity-state-active: var(--opacity-state-active-dense)

.InteractionWrapper_disabled > .InteractionOverlay
  display: none
