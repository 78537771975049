.Brand
  height: 40px
  width: 150px
  background-image: var(--logo-url, url('../../assets/visionlabs-logo.svg'))
  background-size: contain
  background-repeat: no-repeat
  display: block
  color: inherit
  text-decoration: none
  flex-shrink: 0

  &-monochrome
    background-image: var(--logo-url, url('../../assets/visionlabs-logo-monochrome.svg'))

  &-monochromeOnlyName
    background-image: var(--logo-url, url('../../assets/visionlabs-logo-monochrome-only-name.svg'))

  &:hover,
  &:focus
    border: none
    color: inherit

[data-theme="dark"] .Brand
    background-image: var(--dark-logo-url, url('../../assets/visionlabs-logo-dark.svg'))
    &-monochrome
      background-image: var(--dark-logo-url, url('../../assets/visionlabs-logo-monochrome-dark.svg'))
    &-monochromeOnlyName
      background-image: var(--dark-logo-url, url('../../assets/visionlabs-logo-monochrome-dark-only-name.svg'))
