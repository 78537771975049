$sampleSize: 150px

.EventMatchCell
  padding: 5px

.FaceInfoCell
  display: flex
  flex-direction: column
  min-width: 245px

.Sample__face
  height: $sampleSize
  width: $sampleSize

.Sample__body
  border-radius: 4px
  width: $sampleSize
  height: $sampleSize * 2

.SimilarityTag
  font-size: 14px !important
  padding: 5px 5px !important
  text-align: center

.InfoRow_wrapper
  width: 100%
  padding-top: 5px
  margin-right: 10px

.Divider
  margin-top: 5px
