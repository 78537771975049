@use '@vlabs/uikit/src/styles/typography.sass' as *

$sampleSize: 150px

.FaceTopMatch__Sample
  width: $sampleSize

.FaceTopMatch__Info
  margin-left: 10px
  width: 100%

.FaceTopMatch__SimilarityTag
  font-size: 14px !important
  padding: 5px 5px !important
  text-align: center

.FaceTopMatchCell__DeletedFace
  display: inline-flex
  justify-content: center
  align-items: center
