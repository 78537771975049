.Modal
  position: absolute
  top: 50%
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  background: var(--color-surface-overlay)
  box-shadow: var(--elevation-shadow-3)
  -webkit-overflow-scrolling: touch
  outline: none
  width: auto
  height: auto
  overflow: hidden
  opacity: 0

  &--large
    padding: 40px
    min-width: 560px
    max-width: 1280px
    max-height: calc(100vh - 120px)
    border-radius: 20px
    & .Modal__CloseButton
      top: 24px
      right: 24px

  &--small
    padding: 32px // ? вместе с padding от Page слишком много отступа
    min-width: 400px
    max-width: 559px
    border-radius: 16px
    & .Modal__CloseButton
      top: 16px
      right: 16px

  &--scrollable
    overflow: auto

  &.ReactModal__Content--after-open
    opacity: 1
  &.ReactModal__Content--before-close
    opacity: 0

.Modal__Overlay
  position: fixed
  inset: 0px
  z-index: var(--z-index-modal)
  background-color: var(--color-elevation1-color1)
  top: 0
  right: 0
  left: 0
  bottom: 0
  transition: opacity 300ms ease-in-out
  opacity: 0
  &.ReactModal__Overlay--after-open
    opacity: 1
  &.ReactModal__Overlay--before-close
    opacity: 0

.Modal__DocumentBody
  overflow: hidden

.Modal__CloseButton
  position: absolute !important
  right: 0
  top: 0
  z-index: var(--z-index-modal)
