@use '@vlabs/uikit/src/styles/typography.sass' as *

.SettingsItem
  width: 100%

.PageWrapper
  display: flex
  flex-direction: column
  height: calc(100vh - var(--navbarHeight))
  overflow: hidden

.Page
  height: 100%

.ContentWrapper
  margin-top: var(--space-xs)
  display: flex
  flex-direction: row
  flex-grow: 1
  gap: 20px
  height: 100%

.SettingsPanel
  flex-basis: 40%
  padding: var(--space-sm)
  overflow-y: auto

.ChartPanel
  flex-grow: 1
  padding: var(--space-sm)
  overflow: hidden

.Title
  padding: var(--space-sm)
  padding-bottom: 10px
  @include typ-h6

.DeleteButton
  text-align: right

.Hidden
  display: none
