@use '@vlabs/uikit/src/index.sass' as *

$iconSize: 20px

body
  margin: 0
  font-family: 'Open Sans', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 14px

  --color-detection-body: #FF6347
  --color-detection-face: #FFD700

html,
body
  min-height: 100vh

*
  scrollbar-width: thin

*::-webkit-scrollbar
  width: 8px
  height: 10px

*::-webkit-scrollbar-thumb
  border-radius: 0px
  border: 4px solid hsla(0, 0%, 100%, 0)
  background-color: hsla(0, 0%, 63%, 0.3)

.Tooltip_ui
  display: flex
  align-items: center
  margin-left: 5px

.Tooltip_icon
  height: 18px
  color: var(--color-on-surface)

.StaticIcon
  color: var(--color-on-surface-variant2)
  height: $iconSize
  width: $iconSize
  margin-right: 5px

// FIXME: убрать, когда обновим стили в ките
.Stepper
  padding: 10px 0

// FIXME: убрать, когда обновим стили в ките
.TableRow
  display: flex
  justify-content: center

  &:last-child
    border-bottom: none

.Global__Table_fullScreen_wrapper
  padding: 10px

.Global__Table_fullScreen

  .Table__ScrollContainer
    max-height: calc(100vh - 155px)

  .TableCellInner:first-child
    width: 100%
    height: 100%

  .TableBody
    overflow-y: auto
    overflow-x: hidden

  .TableBottomPanel
    margin-top: 0
    padding: 5px
    display: flex
    justify-content: space-between
    align-items: center

  .TableCell:not(.TableCell_action)
    padding: 10px

  .TableRow_Empty
    padding: 10px

    .TableCell
      width: max-content !important

// FIXME: удалить этот стиль когда будет фикс в ките. нельзя прокинуть стиль в таблицу(OneOfFilter) поэтому пока так
.Select__menu
  min-width: 200px !important

.StickyBottomContainer
  padding: 10px
  position: sticky
  bottom: 0
  background-color: var(--color-surface-container)
  box-shadow: 0em 0 1.2em #0e0e0e57
