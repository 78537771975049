.Account
  display: inline-block
  position: relative
  height: 100%
  background: transparent

  &_round
    padding: var(--space-sm)
  &_square
    padding: 4px
    & .InteractionOverlay
      border-radius: 8px

.Account__MenuToggler
  gap: 12px
  display: flex
  flex-direction: row
  align-items: center
  background: transparent
  font-style: normal
  text-decoration: none
  border: none
  cursor: pointer
  position: relative
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  padding: 0

  .Account_round &
    font-size: 12px
    line-height: 16px
    letter-spacing: 0.2px
    font-weight: 400
    padding-right: 24px
  .Account_square &
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.1px
    font-weight: 500
    padding-right: 36px

  .Account_noMenu &
    cursor: default
    padding: 0

.Account__MenuTogglerIcon
  position: absolute
  top: 50%
  transform: translateY(-50%)
  transition: all 0.3s
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  color: var(--color-on-surface)

  .Account:hover &
    opacity: 1
  .Account_round &
    width: 20px
    height: 20px
    right: 0
  .Account_square &
    width: 16px
    height: 16px
    opacity: 0
    right: 12px
  .Account_noMenu &
    display: none

.Account__Avatar
  color: var(--color-on-surface-variant2)
  .Account_square &
    order: -1

.AccountInfo
  display: flex
  flex-direction: column
  color: var(--color-on-surface)

.AccountInfo__Name
  font-style: normal

.AccountInfo__Role
  font-size: 10px
