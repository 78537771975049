$border-radius: 8px

.ButtonToggleGroup
  display: inline-flex
  background: var(--color-surface-container)
  border-radius: $border-radius
  overflow: hidden
  border: 1px solid var(--group-border-color)

  &_primary
    --group-border-color: var(--color-primary)
  &_secondary
    --group-border-color: var(--color-primary-container)

.Button.ButtonToggleGroup__Item
  border-radius: 0
  border-width: 0px !important
  .InteractionOverlay
    border-radius: 0

  & + &
    border-left: 1px solid var(--group-border-color) !important

.ButtonToggleGroup__Icon
  display: flex
  justify-content: center
  height: 1em

  svg
    height: 100%
    width: auto
