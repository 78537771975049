@use '../../styles/typography.sass' as *

.StepperHead
  display: flex
  justify-content: space-between
  margin: 20px 0
  align-items: center

.StepperHead__Item
  background-color: transparent
  padding: 10px
  display: flex
  align-items: center
  flex: 1 1 auto
  position: relative

  &_lastStep
    flex: 0 0 auto

.StepperHead__ItemName
  margin-left: 20px
  width: max-content
  color: var(--color-on-surface)

.StepperHead__Divider
  height: 1px
  background-color: var(--color-outline-variant)
  width: 100%

  &_filled
    background-color: var(--color-primary)

  &_animate_forward
    background: linear-gradient(to right, var(--color-primary) 50%, var(--color-outline-variant) 0)
    background-size: 200% 100%
    background-position: right
    animation: 600ms ease-in-out lineFadeIn forwards

  &_animate_backward
    background: linear-gradient(to left,  var(--color-outline-variant) 50%, var(--color-primary) 0)
    background-size: 200% 100%
    background-position: left
    animation: 600ms ease-in-out lineFadeOut forwards

.Stepper__PageTitle
  margin-bottom: var(--space-sm)

.Stepper__ButtonContainer
  margin-top: var(--space-sm)

.Stepper__Content
  display: none

  &_active
    display: block

.StepperHead__Icon
  position: relative
  border-radius: 50%
  align-items: center
  text-align: center
  display: flex
  gap: 8px

  &_started, &_finished, &_unstarted, &_error, &_disabled
    opacity: 0
    position: absolute
    left: 50%
    top: 50%
    transform: translateY(-50%)
    width: 14px
    height: 14px
    transition: opacity 600ms ease-in-out

    &.visible
      opacity: 1
      transition: opacity 600ms ease-in-out

    & > path
      stroke-width: 2

  &_started
    background-color: var(--color-primary)
    border-radius: 50%

    &::after
      content: ''
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 4px
      height: 4px
      border-radius: 50%
      background-color: var(--color-on-primary)

  &_finished
    color: var(--color-primary)

  &_error
    color:  var(--color-negative-variant)

  &_disabled, &_unstarted
    color: var(--color-on-surface-variant2)
    & + span
      color: var(--color-on-surface-variant2)



@keyframes lineFadeIn
  100%
    background-position: left

@keyframes lineFadeOut
  100%
    background-position: right
