.Root
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  overflow: auto
  justify-content: center
  gap: var(--space-sm)

.Detection
  width: 75px
  position: relative
  cursor: pointer
  overflow: hidden
  border-radius: 4px
  --overlay-bg: var(--color-positive)

  &_selected
    --overlay-bg: var(--color-negative)

.Image
  width: 100%
  height: 100%
  object-fit: cover
  display: block

.SelectionStatus
  position: absolute
  bottom: 5px
  right: 5px
  color: var(--color-positive)
  opacity: 0
  transition: opacity 0.3s

  &_selected
    opacity: 1
