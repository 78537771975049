.Avatar
  width: 40px
  height: 40px

.Avatar__Photo
  width: 100%
  height: 100%
  object-fit: cover
  &-round
    border-radius: 100%
    color: var(--color-on-surface-variant2)
  &-square
    border-radius: 8px
    background: var(--color-accent-blue)
    color: var(--color-on-primary)
    display: flex
    align-items: center
    justify-content: center

.Avatar__Icon
  width: 20px
  height: 20px
