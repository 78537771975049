:root {
    /* opacity */
  --opacity-state-hover: 8%;
  --opacity-state-active: 12%;

  --opacity-state-hover-dense: 20%;
  --opacity-state-active-dense: 26%;
  
    /* box-shadow */
  --elevation-shadow-3: 0px 4px 16px 0px #1F3BA03F;
  --elevation-shadow-2: 0px 4px 10px 0px #1F3BA033;
  --elevation-shadow-1: 0px 3px 8px 0px #1F3BA014;

    /* z-index */
  --z-index-dropdown: 0;
  --z-index-sticky: 1;
  --z-index-fixed: 2;
  --z-index-modal: 3;
  --z-index-popover: 4;
  --z-index-tooltip: 5;
}
