.CopyButton
  align-items: center
  border: none
  outline: none
  background: transparent
  cursor: pointer
  transition: all 0.3s
  padding: 0 !important
  color: var(--color-primary) !important
  width: fit-content

  &::after
    content: ''
    display: block
    position: absolute
    bottom: 8px
    left: 0
    width: 0
    height: 1px
    background: var(--color-primary)
    transition: width 0.2s

  &:hover::after
    width: 100%

.CopyButton__Icon
  transition: all 0.3s
  vertical-align: middle
  color: var(--color-primary)
  width: 16px
  height: 16px
