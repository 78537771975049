.Error
  color: var(--color-on-surface)

  h5
    color: var(--color-negative)
  p
    margin: 0
    padding: 0

  pre
    margin: 0
    padding: 0
    width: 100%
    overflow-x: scroll
    background: var(--color-surface)
    border-radius: 4px
    border: 1px solid var(--color-outline-variant)
    box-sizing: border-box
