@use '@vlabs/uikit/src/styles/typography.sass' as *

@mixin _Button
  position: relative
  display: inline-flex
  justify-content: center
  align-items: center
  padding: 0 16px
  border: none
  border-radius: 8px
  cursor: pointer
  transition: box-shadow 0.2s, color, background-color 0.1s
  background-clip: padding-box
  width: fit-content
  flex-shrink: 0

@mixin filled($color, $on-color)
  background-color: #{$color}
  color: #{$on-color}
  border: none

  &:not(:disabled):hover
    box-shadow: var(--elevation-shadow-1)

  &[disabled]
    color: var(--color-disabled-foreground)
    background: var(--color-disabled-background)

@mixin outlined($on-color, $border-color)
  background-color: transparent
  color: #{$on-color}
  border: 1px solid #{$border-color}

  &[disabled]
    border-color: var(--color-disabled-background)
    color: var(--color-disabled-foreground)

@mixin flat($on-color)
  background-color: transparent
  color: #{$on-color}
  border: none

  &:disabled
    background-color: transparent
    color: var(--color-disabled-foreground)

@mixin invisible($on-color)
  background-color: transparent
  color: #{$on-color}
  border: none

  &:disabled
    background-color: transparent
    color: var(--color-disabled-foreground)

  .InteractionOverlay
    display: none

@mixin dimmed($on-color)
  background-color: transparent
  color: #{$on-color}
  border: none

  &:disabled
    background-color: transparent
    color: var(--color-disabled-foreground)

  .InteractionOverlay
    display: none

.Button
  @include _Button
  --overlay-border-radius: 8px

  &[disabled]
    cursor: default
    .InteractionOverlay
      display: none
  
  &__size
    &_medium
      height: 40px
      @include typ-subtitle-1
      padding: 0 16px
      gap: 6px
    &_small
      height: 32px
      @include typ-subtitle-2
      padding: 0 12px
      gap: 4px

  &.Button_primary
    &.Button_filled
      @include filled(var(--color-primary), var(--color-on-primary))
      --overlay-bg: var(--color-on-primary-hover)

    &.Button_outlined
      @include outlined(var(--color-primary), var(--color-primary))

      --overlay-bg: var(--color-primary)

    &.Button_flat
      @include flat(var(--color-primary))

      --overlay-bg: var(--color-primary)

    &.Button_invisible
      @include invisible(var(--color-primary))

    &.Button_dimmed
      @include dimmed(var(--color-neutral))

      &:not(:disabled):hover
        color: var(--color-primary)

  &.Button_secondary
    &.Button_filled
      @include filled(var(--color-primary-container), var(--color-on-primary-container))
      --overlay-bg: var(--color-primary)

    &.Button_outlined
      @include outlined(var(--color-on-surface), var(--color-outline))

      --overlay-bg: var(--color-neutral)

    &.Button_flat
      @include flat(var(--color-neutral))

      --overlay-bg: var(--color-neutral)

    &.Button_invisible
      @include invisible(var(--color-neutral))

    &.Button_dimmed
      @include dimmed(var(--color-neutral))

      &:not(:disabled):hover
        color: var(--color-on-surface)

  &.Button_negative
    &.Button_filled
      @include filled(var(--color-negative-container-dim), var(--color-negative))
      --overlay-bg: var(--color-negative)

    &.Button_outlined
      @include outlined(var(--color-negative), var(--color-negative))
      --overlay-bg: var(--color-negative)

    &.Button_flat
      @include flat(var(--color-negative))
      --overlay-bg: var(--color-negative)

    &.Button_invisible
      @include invisible(var(--color-negative))

    &.Button_dimmed
      @include dimmed(var(--color-neutral))
      &:not(:disabled):hover
        color: var(--color-negative)

  &.Button_attention
    &.Button_filled
      @include filled(var(--color-attention-container-dim), var(--color-attention))
      --overlay-bg: var(--color-on-primary)

    &.Button_outlined
      @include outlined(var(--color-attention), var(--color-attention))
      --overlay-bg: var(--color-attention)

    &.Button_flat
      @include flat(var(--color-attention))
      --overlay-bg: var(--color-attention)

    &.Button_invisible
      @include invisible(var(--color-attention))

    &.Button_dimmed
      @include dimmed(var(--color-neutral))
      &:not(:disabled):hover
        color: var(--color-attention)

  &.Button_positive
    &.Button_filled
      @include filled(var(--color-positive-container-dim), var(--color-positive))
      --overlay-bg: var(--color-on-primary)

    &.Button_outlined
      @include outlined(var(--color-positive), var(--color-positive))
      --overlay-bg: var(--color-positive)

    &.Button_flat
      @include flat(var(--color-positive))
      --overlay-bg: var(--color-positive)

    &.Button_invisible
      @include invisible(var(--color-positive))

    &.Button_dimmed
      @include dimmed(var(--color-neutral))
      &:not(:disabled):hover
        color: var(--color-positive)

  &.Button_alarm
    &.Button_filled
      @include filled(var(--color-negative), var(--color-on-primary))
      --overlay-bg: var(--color-on-primary)

  // --- modifiers ---

  &_fullWidth
    width: 100%
    font-weight: 500

  &_iconText
    padding: 0 16px 0 12px

  &_onlyIcon
    width: 40px

.RoundButton
  border-radius: 50%
  padding: 6px
  font-size: 16px
  width: 40px

  .Button__Content
    justify-content: center

  .Button__Overlay
    border-radius: 50%

.Button__Icon
  height: 20px

  svg
    height: 100%
    width: auto
    display: block

.Button__Content
  overflow: clip
  position: relative
  white-space: nowrap
  text-overflow: ellipsis
  display: flex
  align-items: center
